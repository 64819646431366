import React, { useState } from "react";
import PageHeading from "../PageHeading";
import SButton from "../../design-system/SButton";
import {
  faBrush,
  faPaintBrush,
  faPaintbrush,
  faPhotoFilm,
  faPlusCircle,
  faUserGroup,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrentPlan from "../billing/CurrentPlan";
import { IWorkspaceUsageVM } from "../../utils/interfaces/IWorkspace";
import { ISubscriptionVM } from "../../utils/interfaces/ISubscriptions";
import { IBaseData } from "../../utils/interfaces/IBase";
import CurrentPlanSkeleton from "../Skeletons/CurrentPlanSkeleton";
import { BrandInviteMemberModal } from "../brand/BrandInviteMemberModal";
import { useNavigate } from "react-router-dom";
import { faMegaphone } from "@fortawesome/pro-regular-svg-icons/faMegaphone";

interface MetricItemProps {
  title: string;
  value?: number;
  icon: IconProp;
  badge?: React.ReactNode;
}

interface IWorkspaceOverviewProps {
  currentPlan?: IBaseData<ISubscriptionVM>;
  limits?: IBaseData<IWorkspaceUsageVM> | undefined;
  isViewer?: boolean;
}

export const MetricItem = ({ title, value, icon, badge }: MetricItemProps) => {
  return (
    <div className="flex flex-col gap-[20px] p-[24px] border border-gray-200 rounded-[12px] shadow-xs bg-base-white">
      <div
        className={
          "rounded-[10px] border-[1px] border-gray-200 bg-white shadow-xs w-[48px] h-[48px] p-[12px]"
        }
      >
        <FontAwesomeIcon
          icon={icon}
          className="text-gray-700 h-[24px] w-[24px] shrink-0"
        />
      </div>
      <div className={"flex flex-col justify-between "}>
        <span className="text-gray-800 text-sm-m">{title}</span>
        <div className="flex justify-between items-center gap-[8px]">
          <span className="text-gray-900 display-md-sb">{value}</span>
          {badge}
        </div>
      </div>
    </div>
  );
};

const WorkspaceOverview = ({
  currentPlan,
  limits,
  isViewer,
}: IWorkspaceOverviewProps) => {
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-between gap-[32px]">
      {openInviteModal && (
        <BrandInviteMemberModal
          setInviteModal={setOpenInviteModal}
          inviteModal={openInviteModal}
          isWorkspaceOnly={true}
        />
      )}
      <div className="grid grid-cols-3 gap-[24px] max-md:grid-cols-1">
        <MetricItem
          title={"Total team members"}
          value={
            limits && limits.data.numberOfMembers + limits.data.numberOfViewers
          }
          icon={faUserGroup}
        />
        <MetricItem
          title={"Posts"}
          value={limits && limits.data.numberOfPosts}
          icon={faPhotoFilm}
        />
        <MetricItem
          title={"Brands"}
          value={limits && limits.data.numberOfOrganizations}
          icon={faPaintBrush}
          badge={
            !isViewer && (
              <SButton
                onClick={() => navigate("/create-brand")}
                type={"primary"}
                size={"sm"}
                lIcon={faPlusCircle}
              >
                Create New
              </SButton>
            )
          }
        />
      </div>
    </div>
  );
};

export default WorkspaceOverview;
