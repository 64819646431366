import {
  faArrowRightFromBracket,
  faBars,
  faBell,
  faBuildings,
  faCommentSmile,
  faCreditCard,
  faGear,
  faLayerGroup,
  faLink,
  faPenToSquare,
  faPlus,
  faQuestionCircle,
  faRefresh,
  faSparkles,
  faUser,
  faVideo,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import TypewriterEffect from "./TypewriterEffect";
import SButton from "../../design-system/SButton";
import { useCopilotStore } from "../../store/copilotStore";
import SSmallMenu from "../../design-system/SSmallMenu";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";
import { useGetUser } from "../../hooks/useUserInfo";
import MobileDrawer from "./MobileDrawer";
import NotificationsInHeader from "./NotificationsInHeader";
import { useGetCurrentBrand } from "../../api/brand/current/get";
import { Tooltip } from "react-tooltip";
import { ViewerRestrictTooltip } from "../utils/ViewerRestrictTooltip";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { toast } from "../../hooks/toast";
import { useGetWorkspaceUsage } from "../../api/workspaces/limits/get";
import WhichSocialNetworksSelector from "../utils/WhichSocialNetworksSelector";
import { useGetWindowWidth } from "../../hooks/useGetWindowWidth";
import AccountHead from "./AccountHead";
import BrandSwitcher from "../newSidebar/BrandSwitcher";
// @ts-ignore
import storiS from "../../assets/images/logos/stori-full.svg";
import { useContentStore } from "../../store/contentStore";

function Header() {
  const { data: currentBrand, isError: isCurrentBrandError } =
    useGetCurrentBrand();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { data: userInfo } = useGetUser();
  const { data: workspaceUsage, isLoading } = useGetWorkspaceUsage();
  const { setPosts, setPage } = useContentStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    queryClient.resetQueries();
    navigate("/login");
  };
  const { handleOpenPost } = useCreationHelperFunctions();
  const [
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
  ] = useState(false);
  const { windowWidth } = useGetWindowWidth();

  return (
    <div className="flex justify-center sticky right-0 top-[12px] mt-[12px] z-[21]">
      <MobileDrawer
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      {isWhichSocialNetworksSelectorOpen && (
        <WhichSocialNetworksSelector
          saveAllOnClose={true}
          isOpen={isWhichSocialNetworksSelectorOpen}
          onClose={() => setIsWhichSocialNetworksSelectorOpen(false)}
        />
      )}
      <div
        className={`
        border border-gray-200
        px-[6px] z-[98] flex justify-between items-center py-[6px]
        w-[calc(100%-64px)] h-[50px] bg-base-white rounded-[12px] gap-[16px] max-sm:w-[calc(100%-16px)]
      `}
      >
        <SButton
          onClick={() => setIsMobileMenuOpen(true)}
          icon={faBars}
          size="sm"
          type="secondaryGray"
          className={"md:hidden"}
        />
        {!isCurrentBrandError && !currentBrand?.data?.isBrandDataGenerated && (
          <Tooltip
            style={{
              fontSize: "14px",
            }}
            id="tooltip-header-copilot"
            place="top"
            disableStyleInjection
          >
            Please wait while we generate your brand
          </Tooltip>
        )}
        {isCurrentBrandError && (
          <Tooltip
            style={{
              fontSize: "14px",
            }}
            id="tooltip-header-copilot"
            place="top"
            disableStyleInjection
          >
            Create brand to use STORI Assistant
          </Tooltip>
        )}
        {/* <div
          data-tooltip-id="tooltip-header-copilot"
          onClick={() => {
            if (userInfo?.data.data.userRole === OrganizationUserRole.VIEWER) {
              toast("Viewers are not allowed to create posts", "error");
              return;
            }
            if (
              !isCurrentBrandError &&
              currentBrand?.data.isBrandDataGenerated
            ) {
              // setIsCopilotOpen(true);
              if (!workspaceUsage?.data.canUserAddPosts) {
                toast(
                  "You have reached the limit of posts for your workspace.",
                  "error"
                );
                return;
              }
              handleOpenPost({
                onSocialNetworkSelectorOpen: () => {
                  setIsWhichSocialNetworksSelectorOpen(true);
                },
              });
            }
          }}
          className="relative h-full cursor-text header-input-border-gradient flex-grow max-w-[320px] flex justify-between items-center px-[12px]"
        >
          <TypewriterEffect />
          <FontAwesomeIcon
            icon={faSparkles}
            className="text-[14px] text-brand-600"
          />
        </div> */}
        <img
          alt="stori-logo"
          src={storiS}
          className="p-1 pl-2 w-[100px] cursor-pointer max-md:hidden"
          onClick={() => {
            navigate("/?subsection=all");
            setPosts([]);
            setPage(1);
            queryClient.invalidateQueries({ queryKey: ["useGetAllCreation"] });
          }}
        />
        <div className="flex items-center gap-[16px] max-md:gap-[10px]">
          {!isCurrentBrandError && (
            <SButton
              onClick={() => {
                if (!workspaceUsage?.data.canUserAddPosts) {
                  toast(
                    "You have reached the limit of posts for your workspace.",
                    "error"
                  );
                  return;
                }
                handleOpenPost({
                  onSocialNetworkSelectorOpen: () => {
                    setIsWhichSocialNetworksSelectorOpen(true);
                  },
                });
              }}
              icon={windowWidth < 680 ? faPenToSquare : undefined}
              lIcon={windowWidth >= 680 ? faPlus : undefined}
              size="sm"
              type="primary"
              disabled={
                userInfo?.data?.data.userRole === OrganizationUserRole.VIEWER
              }
              children={
                windowWidth >= 680 ? (
                  <>
                    Create Post
                    <ViewerRestrictTooltip />
                  </>
                ) : undefined
              }
            />
          )}
          <NotificationsInHeader />
          <img
            id="header-profile-image"
            onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
            src={
              userInfo?.data.data.photoUrl ??
              "https://cdn.storiai.com/avatar.svg"
            }
            alt="avatar"
            className="cursor-pointer w-[36px] h-[36px] object-cover rounded-full"
          />
          {isProfileMenuOpen && (
            <SSmallMenu
              isOpen={isProfileMenuOpen}
              className="right-[32px] left-[unset] w-fit z-100"
              setIsOpen={(p) => setIsProfileMenuOpen(p)}
              excludedId="header-profile-image"
              data={[
                [
                  {
                    text: userInfo && <AccountHead data={userInfo.data.data} />,
                    handleClick: () => navigate("/account"),
                  },
                  {
                    text: "Integrations",
                    icon: faLink,
                    handleClick: () => navigate("/integrations"),
                  },
                  {
                    text: "Workspace",
                    icon: faBuildings,
                    handleClick: () => navigate("/workspace?section=overview"),
                  },
                  ...(userInfo?.data.data.userRole ===
                  OrganizationUserRole.VIEWER
                    ? []
                    : [
                        {
                          text: "Billing",
                          icon: faCreditCard,
                          handleClick: () =>
                            navigate("/workspace?section=billing"),
                        },
                      ]),
                  {
                    text: "Settings",
                    icon: faGear,
                    handleClick: () => navigate("/workspace?section=overview"),
                  },
                ],
                [
                  {
                    text: "What's New",
                    icon: faSparkles,
                    handleClick: () =>
                      window.open("https://stori.canny.io/changelog", "_blank"),
                  },
                  {
                    text: "Discord",
                    icon: faCommentSmile,
                    handleClick: () =>
                      window.open("https://discord.gg/34SQh4cmMk", "_blank"),
                  },
                  {
                    text: "Support",
                    icon: faQuestionCircle,
                    handleClick: () =>
                      window.open("https://storiai.com/contact", "_blank"),
                  },
                ],
                [
                  {
                    text: "Logout",
                    icon: faArrowRightFromBracket,
                    handleClick: () => handleLogout(),
                    color: "text-red-500",
                  },
                ],
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
