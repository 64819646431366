import React, { useEffect, useState } from "react";
import SModalBase from "../../../design-system/SModalBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faChevronDown,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import DatePicker from "./schedule/DatePicker";
import TimePicker from "./schedule/TimePicker";
import UpcomingPosts from "./schedule/UpcomingPosts";
import SButton from "../../../design-system/SButton";
import DatePickerModal from "./schedule/DatePickerModal";
import TimePickerModal from "./schedule/TimePickerModal";
import { usePublishing } from "../hooks/usePublishing";
import { useCreationStore } from "../../../store/creationStore";
import { toast } from "../../../hooks/toast";
import { useGetUser } from "../../../hooks/useUserInfo";
import { formatDate } from "date-fns";

function ScheduleModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { data: userInfo } = useGetUser();
  const { isPublishing, setSelectedSocialNetworks } = useCreationStore();
  const { handlePublishNow, handleSchedule } = usePublishing();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<Date>(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);

  return (
    <SModalBase
      dialogPanelClassName="max-w-[600px] w-full !p-0"
      isOpen={isOpen}
      showX={false}
      onClose={onClose}
    >
      <div className="flex justify-between px-[24px] items-center py-[16px] border-b border-gray-200">
        <p className="text-md-m text-gray-600">Schedule Post</p>
        <FontAwesomeIcon
          onClick={onClose}
          icon={faX}
          className="text-[12px] text-gray-600"
        />
      </div>
      <div className="w-full flex flex-col gap-[12px] p-[24px]">
        <p className="text-sm text-gray-600">
          Post will be scheduled according to your current timezone{" "}
          {userInfo?.data?.data?.timezone}
        </p>
        <div className="flex gap-[12px] relative">
          <DatePicker
            selectedDate={selectedDate}
            setIsDatePickerOpen={setIsDatePickerOpen}
            isDatePickerOpen={isDatePickerOpen}
          />
          <TimePicker
            isTimePickerOpen={isTimePickerOpen}
            selectedTime={selectedTime}
            setIsTimePickerOpen={setIsTimePickerOpen}
          />
          {isTimePickerOpen && (
            <TimePickerModal
              selected={selectedTime}
              setSelected={setSelectedTime}
              setIsTimePickerOpen={setIsTimePickerOpen}
            />
          )}
          {isDatePickerOpen && (
            <DatePickerModal
              selected={selectedDate}
              setSelected={setSelectedDate}
              setIsDatePickerOpen={setIsDatePickerOpen}
            />
          )}
        </div>
        <UpcomingPosts selectedDate={selectedDate} />
        <div className="pt-[12px] flex justify-end gap-[12px]">
          <SButton
            type="secondaryGray"
            onClick={() => {
              handlePublishNow().then((res) => {
                setSelectedSocialNetworks([]);
                onClose();
              });
            }}
            isLoading={isPublishing === "publishing"}
            disabled={isPublishing ? isPublishing !== "publishing" : false}
          >
            Publish now
          </SButton>
          <SButton
            onClick={() => {
              if (selectedTime < new Date() && formatDate(selectedDate, 'yyyy-MM-dd').toString() === formatDate(new Date(), 'yyyy-MM-dd').toString()) {
                toast("Please select a future time", "error");
                return;
              }
              handleSchedule({ date: selectedDate, time: selectedTime }).then(
                (res) => {
                  toast("Post scheduled successfully", "success");
                  setSelectedSocialNetworks([]);
                  onClose();
                }
              );
            }}
            className="max-w-[204px] w-full"
            isLoading={isPublishing === "scheduling"}
            disabled={isPublishing ? isPublishing !== "scheduling" : false}
          >
            Schedule
          </SButton>
        </div>
      </div>
    </SModalBase>
  );
}

export default ScheduleModal;
