import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IStockGroupVM } from "../../../utils/interfaces/IPost";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { useCreationStore } from "../../../store/creationStore";
import { ImageGenerationStyle } from "../../../utils/enums/Enums";
import { ISingleFIleVM } from "../../../utils/interfaces/IAssets";

export const usePutRemoveSchedule = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<string>,
    Error,
    {
      socialNetworkPostId: number;
    }
  >({
    mutationFn: ({ socialNetworkPostId }) =>
      makeRequest.put(
        `/Posts/SocialNetworkPost/${socialNetworkPostId}/PrepareForEditing`
      ),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFiles"] });
    },
  });
};
