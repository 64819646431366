import React, { useEffect, useState } from "react";
import AIMessage from "./Brief/AIMessage";
import UserMessage from "./Brief/UserMessage";
import Textarea from "./Brief/Textarea";
import { useCreationStore } from "../../../store/creationStore";
import MessageLoader from "./Brief/MessageLoader";
import { useUpdatePost } from "../hooks/useUpdatePost";
import { useFillChat } from "./Brief/hooks/useFIllChat";
import { useBriefHelperFunctions } from "./Brief/hooks/useBriefHelperFunctions";
import { useReturnedTextVersions } from "./Brief/hooks/useReturnedTextVersions";
import { CopilotRole } from "../../../utils/enums/Enums";
import ToolMessage from "./Brief/ToolMessage";
import { useUploadFiles } from "../../../hooks/useUploadFiles";
import UploadedFilesView from "./Brief/UploadedFilesView";
import { useFillSuggestedPrompts } from "../hooks/useFillSuggestedPrompts";
import SuggestedPrompts from "../SuggestedPrompts";
//@ts-ignore
import jason from "../.../../../../../src/assets/images/agents/jason.png";
import AgentInfo from "./Brief/AgentInfo";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";

function Brief({
  setIsUpdatedByCopilot,
}: {
  setIsUpdatedByCopilot: (value: boolean) => void;
}) {
  const { briefMessages, copilotSessionId } = useCreationStore();
  const { updateStory } = useUpdatePost();
  const [chatResponse, setChatResponse] = useState("");
  const [disable, setDisable] = useState(false);
  const [value, setValue] = useState("");
  const { selectFile, files, setFiles } = useUploadFiles();
  const { sendMessage } = useBriefHelperFunctions({
    setFiles,
    files,
    setChatResponse,
    setDisable,
    setValue,
  });
  const { initialLoad } = useFillChat({
    setChatResponse,
    setDisable,
    setValue,
  });

  useReturnedTextVersions(initialLoad, setIsUpdatedByCopilot);
  useFillSuggestedPrompts();

  useEffect(() => {
    document.getElementById("copilotPostBriefChat")?.scrollTo({
      top: 1000000,
      left: 0,
      behavior: "smooth",
    });
  }, [briefMessages]);

  const handleGetStory = async (story: string) => {
    await updateStory(story);
    await sendMessage(`Generate post:${story}`, true);
  };
  const { isPostPublishedOrScheduled } = useCreationHelperFunctions();
  return (
    <div className="bg-base-white border-[1px] rounded-[12px] h-full border-gray-200 shadow-xs flex flex-col max-md:rounded-[0px] max-md:border-0 max-md:rounded-b-0">
      <div
        id="copilotPostChatHeader"
        className="flex bg-gray-25 max-md:bg-white rounded-t-[12px] gap-[8px] items-center border-b border-b-gray-100 px-[24px] py-[10px] max-md:px-[16px] max-md:py-[8px] max-md:gap-[4px] max-md:pt-[8px] max-md:pb-[8px]"
      >
        <AgentInfo
          logo={jason}
          agentName={"Jason"}
          agentDescription={
            "Hi there, I'm Jason!" +
            "\n \n" +
            "- I can format, change, adapt text\n" +
            "- I can generate images\n" +
            "- I can work on multiple social media\n" +
            "- I know your brand already\n" +
            "- I can recognize image and video content\n" +
            "- I can perform actions while talking with you \n" +
            "- I can open links you send me and gather info" +
            "\n \n" +
            "Just tell me what you need, and I'm happy to help!"
          }
          agentRole={"Content Creator"}
        >
          <div className="flex gap-[12px] items-center">
            <img
              src={jason}
              alt="jason-avatar"
              className="w-[35px] h-[35px] rounded-full cursor-pointer"
            />
            <p className="text-md-sb text-gray-900">Jason</p>
          </div>
        </AgentInfo>
      </div>
      <div
        id="copilotPostBriefChat"
        className="flex flex-col gap-[8px] overflow-y-auto flex-grow hide__scroll px-[24px] py-[32px] max-md:px-[16px] max-md:py-[16px] max-md:gap-[4px] max-md:pt-[16px] max-md:pb-[16px]"
      >
        {briefMessages.length === 0 && (
          <>
            <MessageLoader />
          </>
        )}
        {briefMessages.map((item, i) => (
          <>
            {item.role === CopilotRole.TOOL && <ToolMessage msg={item} />}
            {item.role === CopilotRole.ASSISTANT && (
              <AIMessage
                handleGetStory={handleGetStory}
                messages={briefMessages}
                index={i}
                sendMessage={sendMessage}
                msg={item}
              />
            )}
            {item.role === CopilotRole.USER && <UserMessage msg={item} />}
          </>
        ))}
      </div>
      <UploadedFilesView files={files} setFiles={setFiles} />
      {!isPostPublishedOrScheduled() && (
        <SuggestedPrompts
          disable={disable}
          sendMessage={sendMessage}
          setValue={setValue}
        />
      )}
      <Textarea
        files={files}
        selectFile={selectFile}
        disable={disable}
        value={value}
        setValue={setValue}
        sendMessage={sendMessage}
      />
    </div>
  );
}

export default Brief;
