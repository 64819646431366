import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { IFilesVM } from "../../../utils/interfaces/IAssets";

export const useGetFiles = ({
  page,
  pageSize,
  keyword,
  fileType,
  generatedOnly = false,
  uploadedOnly = false,
  postId,
}: {
  page: number;
  pageSize: number;
  keyword?: string;
  fileType?: string;
  generatedOnly?: boolean;
  uploadedOnly?: boolean;
  postId?: number;
  parentFolderId?: null | number;
}) => {
  return useQuery<IBaseData<IFilesVM>>({
    queryKey: [
      "useGetFiles",
      page,
      keyword,
      fileType,
      generatedOnly,
      uploadedOnly,
      postId,
    ],
    queryFn: async () => {
      let endpoint = `/Files?Page=${page}&PageSize=${pageSize}`;

      if (keyword) endpoint += `&Keyword=${keyword}`;
      if (uploadedOnly) endpoint += `&UploadedOnly=${uploadedOnly}`;
      if (generatedOnly) endpoint += `&GeneratedOnly=${generatedOnly}`;
      if (fileType) endpoint += `&FileType=${fileType}`;
      if (postId) endpoint += `&PostId=${postId}`;

      const response = await makeRequest.get(endpoint);
      return response as IBaseData<IFilesVM>;
    },
    // enabled: enabled,
  });
};
