import React from "react";
import SuggestedPrompts from "./DefaultState/SuggestedPrompts";
import {
  ImageGenerationAspectRatio,
  ImageGenerationStyle,
} from "../../../../../utils/enums/Enums";
import Generations from "./DefaultState/Generations";
import GenerationActions from "./DefaultState/GenerationActions";

function DefaultState({
  setInputValue,
  setActiveTab,
  activeStyleTag,
  setActiveStyleTag,
  activeAspectRatio,
  setActiveAspectRatio,
}: {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab: React.Dispatch<
    React.SetStateAction<
      "search" | "generator" | "stocks" | "selector" | "gifs"
    >
  >;
  activeStyleTag: ImageGenerationStyle;
  setActiveStyleTag: React.Dispatch<React.SetStateAction<ImageGenerationStyle>>;
  activeAspectRatio: ImageGenerationAspectRatio;
  setActiveAspectRatio: React.Dispatch<
    React.SetStateAction<ImageGenerationAspectRatio>
  >;
}) {
  return (
    <>
      <GenerationActions
        activeStyleTag={activeStyleTag}
        setActiveStyleTag={setActiveStyleTag}
        activeAspectRatio={activeAspectRatio}
        setActiveAspectRatio={setActiveAspectRatio}
      />
      <SuggestedPrompts setInputValue={setInputValue} />
      <Generations setActiveTab={setActiveTab} />
    </>
  );
}

export default DefaultState;
