import { useState } from "react";
import Platform, {
  IPlaformPageItem,
} from "../../components/platforms/Platform";
import { SocialNetworkType } from "../../utils/enums/Enums";
import { useGetAllConnections } from "../../api/connections/get";
import { usePostConnectPage } from "../../api/connections/connect/post";
import { useDeleteConnection } from "../../api/connections/delete";
import { toast } from "../../hooks/toast";
import { useBrandStore } from "../../store/brandStore";
import { usePutBrandActiveSocialNetworks } from "../../api/brand/selectSocialNetworks/put";
import _ from "lodash";
function BrandConnections() {
  const { data: connections } = useGetAllConnections();
  const [connectPageLoading, setConnectPageLoading] = useState<null | number>(
    null
  );
  const { mutateAsync: putBrandActiveSocialNetworks } =
    usePutBrandActiveSocialNetworks();
  const { mutateAsync: connectPage } = usePostConnectPage();
  const { mutateAsync: deleteConnection } = useDeleteConnection();
  let mappedData = connections
    ? connections?.data.map(
        (item) =>
          ({
            id: item.id,
            logo: item.logoUrl,
            socialNetworkType: item.socialNetworkType,
            title: item.name,
          }) as IPlaformPageItem
      )
    : undefined;

  const handleConnect = (socialNetworkType: SocialNetworkType) => {
    setConnectPageLoading(socialNetworkType);
    connectPage({ socialNetworkType })
      .then((res) => {
        window.location.href = res.data;
        // window.open(res.data, "_blank");
      })
      .catch((err) => toast(err?.response?.data?.message))
      .finally(() => {
        setConnectPageLoading(null);
      });
  };
  const handleDelete = (id: number) => {
    deleteConnection({ id }).then(() => {
      toast("Connection deleted successfully", "success");
    });
  };
  const { currentBrand } = useBrandStore();

  const handleSetActive = ({
    socialNetworkType,
    isActive,
  }: {
    socialNetworkType: SocialNetworkType;
    isActive: boolean;
  }) => {
    if (currentBrand) {
      let tmp = _.cloneDeep(currentBrand).activeSocialNetworks;
      if (isActive) {
        tmp.push(socialNetworkType);
      } else {
        tmp = tmp.filter((item) => item !== socialNetworkType);
      }
      putBrandActiveSocialNetworks({
        brandId: currentBrand.id,
        socialNetworkTypes: tmp,
      });
    }
  };

  return (
    <>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-[20px] gap-[16px] mb-[40px] max-md:grid-cols-1">
        {mappedData && (
          <>
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.TWITTER
              )}
              handleConnect={() => handleConnect(SocialNetworkType.TWITTER)}
              socialNetworkType={SocialNetworkType.TWITTER}
              isActive={
                currentBrand
                  ? currentBrand.activeSocialNetworks.includes(
                      SocialNetworkType.TWITTER
                    )
                  : false
              }
              setIsActive={(p) =>
                handleSetActive({
                  socialNetworkType: SocialNetworkType.TWITTER,
                  isActive: p,
                })
              }
            />
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.LINKEDIN
              )}
              handleConnect={() => handleConnect(SocialNetworkType.LINKEDIN)}
              socialNetworkType={SocialNetworkType.LINKEDIN}
              isActive={
                currentBrand
                  ? currentBrand.activeSocialNetworks.includes(
                      SocialNetworkType.LINKEDIN
                    )
                  : false
              }
              setIsActive={(p) =>
                handleSetActive({
                  socialNetworkType: SocialNetworkType.LINKEDIN,
                  isActive: p,
                })
              }
            />{" "}
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              handleConnect={() => handleConnect(SocialNetworkType.INSTAGRAM)}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.INSTAGRAM
              )}
              socialNetworkType={SocialNetworkType.INSTAGRAM}
              isActive={
                currentBrand
                  ? currentBrand.activeSocialNetworks.includes(
                      SocialNetworkType.INSTAGRAM
                    )
                  : false
              }
              setIsActive={(p) =>
                handleSetActive({
                  socialNetworkType: SocialNetworkType.INSTAGRAM,
                  isActive: p,
                })
              }
            />{" "}
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.PINTEREST
              )}
              handleConnect={() => handleConnect(SocialNetworkType.PINTEREST)}
              socialNetworkType={SocialNetworkType.PINTEREST}
              isActive={
                currentBrand
                  ? currentBrand.activeSocialNetworks.includes(
                      SocialNetworkType.PINTEREST
                    )
                  : false
              }
              setIsActive={(p) =>
                handleSetActive({
                  socialNetworkType: SocialNetworkType.PINTEREST,
                  isActive: p,
                })
              }
            />
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              handleConnect={() => handleConnect(SocialNetworkType.FACEBOOK)}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.FACEBOOK
              )}
              socialNetworkType={SocialNetworkType.FACEBOOK}
              isActive={
                currentBrand
                  ? currentBrand.activeSocialNetworks.includes(
                      SocialNetworkType.FACEBOOK
                    )
                  : false
              }
              setIsActive={(p) =>
                handleSetActive({
                  socialNetworkType: SocialNetworkType.FACEBOOK,
                  isActive: p,
                })
              }
            />
            {/* <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              handleConnect={() => handleConnect(SocialNetworkType.REDDIT)}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.REDDIT
              )}
              socialNetworkType={SocialNetworkType.REDDIT}
            />
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              handleConnect={() => handleConnect(SocialNetworkType.TELEGRAM)}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.TELEGRAM
              )}
              socialNetworkType={SocialNetworkType.TELEGRAM}
            />
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              handleConnect={() => handleConnect(SocialNetworkType.SLACK)}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.SLACK
              )}
              socialNetworkType={SocialNetworkType.SLACK}
            />
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              handleConnect={() => handleConnect(SocialNetworkType.DISCORD)}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.DISCORD
              )}
              socialNetworkType={SocialNetworkType.DISCORD}
            /> */}
          </>
        )}
      </div>
    </>
  );
}

export default BrandConnections;
