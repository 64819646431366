import { usePostComment } from "../../../api/creation/comment/post";
import { usePutRemoveSchedule } from "../../../api/creation/removeSchedule/put";
import { useDeletePost } from "../../../api/posts/delete";
import { usePatchPost } from "../../../api/posts/patch";
import { usePutPost } from "../../../api/posts/put";
import { usePatchSocialNetworkPost } from "../../../api/posts/socialNetworkPost/patch";
import { useCreationStore } from "../../../store/creationStore";
import { PostStatus } from "../../../utils/enums/Enums";
import { IBase, IBaseData } from "../../../utils/interfaces/IBase";
import {
  IPostSingleCommentVM,
  ISinglePostVM,
  ISocialNetworkPostFileVM,
  ISocialNetworkPostVM,
} from "../../../utils/interfaces/IPost";
import { useCreationHelperFunctions } from "./useCreationHelperFunctions";
import _ from "lodash";
import { useSocialScore } from "./useSocialScore";
export const useUpdatePost = () => {
  const { mutateAsync: updatePost } = usePatchPost();
  const { mutateAsync: updateSocialNetworkPost } = usePatchSocialNetworkPost();
  const { postData, setPostData } = useCreationStore();
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const { mutateAsync: putPost } = usePutPost();
  const { mutateAsync: sendComment } = usePostComment();
  const { mutateAsync: putRemoveSchedule } = usePutRemoveSchedule();
  const { mutateAsync: deletePost, isPending: isDeleting } = useDeletePost();
  const { generateSocialScore } = useSocialScore();
  // Base Functions
  const updateSocialNetworkPostProperty = ({
    socialNetworkPostId,
    property,
    value,
  }: {
    property: keyof ISocialNetworkPostVM;
    value: any;
    socialNetworkPostId?: number;
  }) => {
    const newPostData = _.cloneDeep(useCreationStore.getState().postData);
    const currentSocialNetworkPostId =
      socialNetworkPostId ?? getActiveSocialNetworkPost()?.id;
    if (currentSocialNetworkPostId) {
      const postIndex = newPostData!.socialNetworkPosts.findIndex(
        (post) => post.id === currentSocialNetworkPostId
      );
      if (postIndex !== -1) {
        (newPostData!.socialNetworkPosts[postIndex] as any)[property] = value;
      }
    }

    return newPostData as ISinglePostVM;
  };

  const updateSocialNetworks = async (socPosts: ISocialNetworkPostVM[]) => {
    if (postData) {
      let tmp = { ...postData };
      tmp.socialNetworkPosts = socPosts;
      setPostData(tmp);
      await updatePost({
        postId: postData.id,
        path: "socialNetworkPosts",
        value: socPosts,
      });
    }
  };

  const updateStory = async (story: string) => {
    if (postData) {
      let tmp = { ...postData };
      tmp.story = story;
      setPostData(tmp);
      await updatePost({ postId: postData.id, path: "story", value: story });
    }
  };

  const updateTextRequest = async ({
    socialNetworkPostId,
    text,
  }: {
    socialNetworkPostId?: number;
    text: string;
  }) => {
    if (postData) {
      const postId = socialNetworkPostId ?? getActiveSocialNetworkPost()?.id;
      if (postId) {
        await updateSocialNetworkPost({
          socialNetworkPostIds: [postId],
          path: "Text",
          value: text,
          op: "replace",
        });
      }
    }
  };
  const updateTextUI = async ({
    socialNetworkPostId,
    text,
    sendRequest = false,
  }: {
    socialNetworkPostId?: number;
    text: string;
    sendRequest?: boolean;
  }) => {
    if (postData) {
      let tmpPostData = _.cloneDeep(postData);
      // tu teksti chawere da sxva tekstebi nullia mashin sxva tekstebsac unda gadaaweros tavze
      let nullText = 0;
      postData.socialNetworkPosts.map((item) => {
        if (
          item.text === getActiveSocialNetworkPost()?.text ||
          item.text === null ||
          item?.text.length === 0
        ) {
          nullText = nullText + 1;
        }
      });
      if (nullText >= 2) {
        updateAllTextWhereTextIsNullOrSame({
          text,
          sendRequest,
        });
      } else {
        setPostData(
          updateSocialNetworkPostProperty({
            socialNetworkPostId: socialNetworkPostId ?? undefined,
            property: "text",
            value: text,
          })
        );
        if (sendRequest) {
          await updateTextRequest({
            text,
            socialNetworkPostId,
          }).then((res) => {
            generateSocialScore();
          });
        }
      }
    }
  };

  const updateFilesUI = async ({
    socialNetworkPostId,
    files,
    sendRequest = false,
  }: {
    socialNetworkPostId?: number;
    files: ISocialNetworkPostFileVM[];
    sendRequest?: boolean;
  }) => {
    setPostData(
      updateSocialNetworkPostProperty({
        socialNetworkPostId,
        property: "files",
        value: files,
      })
    );
    if (sendRequest) {
      await updateFilesRequest({ socialNetworkPostId, files });
    }
  };
  const updateFilesRequest = async ({
    socialNetworkPostId,
    files,
  }: {
    socialNetworkPostId?: number;
    files: ISocialNetworkPostFileVM[];
  }) => {
    if (postData) {
      const socNetworkPostId =
        socialNetworkPostId ?? getActiveSocialNetworkPost()?.id;
      if (socNetworkPostId) {
        let tmpPostData = _.cloneDeep(postData);
        tmpPostData.socialNetworkPosts.map((item) => {
          if (item.id === socNetworkPostId) {
            item.files = files;
          }
        });
        await putPost({
          postData: tmpPostData,
          postId: tmpPostData.id,
        });
      }
    }
  };

  const updateAllTextWhereTextIsNullOrSame = async ({
    text,
    sendRequest = false,
  }: {
    sendRequest?: boolean;
    text: string;
  }) => {
    let tmpPostData = _.cloneDeep(postData);
    tmpPostData?.socialNetworkPosts.map((socItem) => {
      if (
        socItem.text === getActiveSocialNetworkPost()?.text ||
        socItem.text === null ||
        socItem?.text.length === 0
      ) {
        socItem.text = text;
      }
    });
    setPostData(tmpPostData as ISinglePostVM);
    if (sendRequest)
      await putPost({
        postData: tmpPostData as ISinglePostVM,
        postId: postData!.id,
      }).then((res) => {
        generateSocialScore();
      });
  };

  const updateSocialNetworkPostStatus = async ({
    status,
    socialNetworkPostId,
  }: {
    status: PostStatus;
    socialNetworkPostId: number;
  }) => {
    const tmpPostData = updateSocialNetworkPostProperty({
      socialNetworkPostId: socialNetworkPostId ?? undefined,
      property: "postStatus",
      value: status,
    });
    setPostData(tmpPostData);
    // return await updateFullPost(tmpPostData);
    //     socialNetworkPostId: socialNetworkPostId ?? undefined,
    //     property: "postStatus",
    //     value: status,
    //   })
    // );
  };

  const updateFullPost = async (postData: ISinglePostVM) => {
    return await putPost({
      postData,
      postId: postData.id,
    });
  };
  const updateComments = async ({
    text,
    taggedUserIds,
  }: {
    text: string;
    taggedUserIds: string[];
  }): Promise<IBaseData<IPostSingleCommentVM>> => {
    try {
      const res = await sendComment({
        commentText: text,
        postId: postData!.id,
        taggedUserIds: taggedUserIds,
      });
      if (postData) {
        const tmpPostData = _.cloneDeep(postData);
        tmpPostData!.comments.push(res.data);
        setPostData(tmpPostData);
      }
      return res;
    } catch (e) {
      throw e;
    }
  };

  const removeSchedule = (id: number | null) => {
    let tmpPostData = _.cloneDeep(postData);
    if (!tmpPostData) return;
    if (id) {
      tmpPostData.socialNetworkPosts.map((item) => {
        if (item.id === id) {
          item.postStatus = PostStatus.DRAFT;
          item.releaseDate = null;
        }
      });
    } else {
      tmpPostData.socialNetworkPosts.map((item) => {
        if (item.id === getActiveSocialNetworkPost()!.id) {
          item.postStatus = PostStatus.DRAFT;
          item.releaseDate = null;
        }
      });
    }
    putRemoveSchedule({
      socialNetworkPostId: id || getActiveSocialNetworkPost()!.id,
    });
    setPostData(tmpPostData);
  };

  const updateName = async (name: string) => {
    if (postData) {
      let tmp = { ...postData };
      tmp.name = name;
      setPostData(tmp);
      await updatePost({ postId: postData.id, path: "name", value: name });
    }
  };

  const handleDeletePost = async (
    postId: number,
    deleteFromSocialNetwork?: boolean
  ): Promise<IBaseData<string>> => {
    try {
      return await deletePost({
        postId,
        deleteFromSocialNetwork,
      });
    } catch (e) {
      throw e;
    }
  };

  const updateSocialScoreIntoPost = async () => {
    if (!postData) return;
    let tmpPostData = _.cloneDeep(postData);
    tmpPostData.socialNetworkPosts.map((item) => {
      if (item.socialNetworkType === useCreationStore.getState().activeSoc) {
        item.socialScore = useCreationStore.getState().socialScore;
      }
    });
    setPostData(tmpPostData);
  };

  return {
    handleDeletePost,
    updateSocialScoreIntoPost,
    updateName,
    updateStory,
    updateTextUI,
    updateFilesUI,
    updateTextRequest,
    updateSocialNetworks,
    updateFullPost,
    removeSchedule,
    updateComments,
    updateSocialNetworkPostStatus,
    isDeleting,
  };
};
