import { useLocation, useNavigate } from "react-router-dom";
import { getLastSegment, getQuery } from "../../hooks/functions/queries";
import { ISidebarPage } from "./NavigationGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import { AnimatePresence } from "framer-motion";
import { DesktopSubMenu } from "./DesktopSubMenu";

function NavigationGroupItem({
  title,
  icon,
  solidIcon,
  path,
  prePages,
  handleClick,
  alwaysActive = false,
  isSoon = false,
  subMenuItems,
  subMenuDirection = "down",
  pathTitle,
}: ISidebarPage) {
  const location = useLocation();
  const id = "menu_item_" + Math.random();
  const lastSegment = getLastSegment(location.pathname);
  let isActive = `/${lastSegment}${location.search}` === path;
  if (getQuery("subsection")) {
    isActive = lastSegment === pathTitle;
  }
  const navigate = useNavigate();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const subMenuRef = useRef<HTMLDivElement>(null);
  useHandleOutsideClick({
    ref: subMenuRef,
    callBack: () => {
      setShowSubMenu(false);
    },
    excludedIds: [id],
  });
  return (
    <div id={id} className="flex flex-col gap-1">
      <div
        className={`${isActive && "max-md:bg-gray-200"} max-h-[80px] md:flex-col select-none rounded-[6px] px-[12px] md:px-[8px] group flex max-md:gap-[12px] md:gap-[2px] py-[8px] items-center cursor-pointer`}
        onMouseEnter={() => {
          if (!subMenuItems) return;
          timeoutId && clearTimeout(timeoutId);
          setTimeoutId(setTimeout(() => setShowSubMenu(true), 800));
        }}
        onMouseLeave={() => {
          if (!subMenuItems) return;
          timeoutId && clearTimeout(timeoutId);
          setShowSubMenu(false);
        }}
        onClick={() => {
          if (handleClick) {
            handleClick();
            navigate(path || "/");
          } else {
            navigate(path || "/");
          }
        }}
      >
        <div
          className={`${isActive && "md:bg-gray-100"} md:hover:bg-gray-100 flex jusity-center items-center py-2 rounded-[6px] md:h-full md:w-full`}
        >
          <FontAwesomeIcon
            icon={isActive ? solidIcon : icon}
            className={`${!alwaysActive && !isActive && "group-hover:text-gray-800"} max-md:text-[16px] md:text-[20px] text-gray-600 md:w-full max-md:w-[18px]`}
          />
        </div>
        <p
          className={`text-md-sb text-gray-700 md:text-[12px] transition-all ${!alwaysActive && !isActive && " group-hover:text-gray-800"}text-gray-700 `}
        >
          {title}
        </p>
        {isSoon && (
          <div className="flex items-center px-[8px] py-0 rounded-full border border-gray-200 bg-gray-50 text-gray-700 text-center text-xs-m max-md:hidden">
            soon
          </div>
        )}
        <AnimatePresence>
          {showSubMenu && (
            <DesktopSubMenu
              subMenuDirection={subMenuDirection}
              subMenuItems={subMenuItems}
              subMenuRef={subMenuRef}
              title={title}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default NavigationGroupItem;
