import { faRefresh, faSpinner, faX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useCreationStore } from "../../../../store/creationStore";
import { isVideo } from "../../../Library/hooks/getFileType";

function UploadedFilesView({
  files,
  setFiles,
}: {
  files: File[] | null;
  setFiles: React.Dispatch<React.SetStateAction<File[] | null>>;
}) {
  const { isImageUploadingInChat } = useCreationStore();
  return (
    <>
      {files && files.length > 0 && (
        <div className="group mt-[12px] flex gap-[8px] px-[16px]">
          {files?.map((item) => (
            <div className="w-[60px] h-[60px] relative border border-gray-200 shadow-xs rounded-[4px]">
              {isImageUploadingInChat && (
                <div className="absolute w-full h-full flex justify-center items-center bg-base-black bg-opacity-50 rounded-[4px]">
                  <FontAwesomeIcon
                    icon={faRefresh}
                    spin={true}
                    className={"text-white"}
                  />
                </div>
              )}
              <div
                onClick={() => {
                  const filteredFiles = files.filter((file) => file !== item);
                  setFiles(filteredFiles);
                }}
                className="hidden group-hover:flex absolute top-[-10px] right-[-10px] w-[20px] h-[20px] cursor-pointer shadow-sm border border-gray-400 bg-base-white rounded-full justify-center items-center"
              >
                <FontAwesomeIcon icon={faX} className="text-[10px]" />
              </div>
              {isVideo(item.name) ? (
                <video
                  className="w-full h-full object-cover cursor-pointer rounded-[4px]"
                  src={URL.createObjectURL(item)}
                />
              ) : (
                <img
                  className="w-full h-full object-cover cursor-pointer rounded-[4px]"
                  src={URL.createObjectURL(item)}
                  alt="thumbnail"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default UploadedFilesView;
