import { faArrowsAltV, IconDefinition } from "@fortawesome/pro-regular-svg-icons";

export enum TeamRole {
  OWNER = 1,
  MEMBER = 2,
  VIEWER = 3,
}

export enum InvitationStatus {
  SENT = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  CANCELED = 4,
}

export enum TeamPackageStatus {
  ACTIVE = 1,
  PAUSED = 2,
  REFUNDED = 6,
  CANCELED = 8,
}

export enum PostStatus {
  DRAFT = 1,
  PUBLISHED = 2,
  DELETED = 3,
  SCHEDULED = 4,
  REMINDER = 5,
}

export enum FileType {
  IMAGE = 1,
  VIDEO = 2,
  TEXT = 3,
}

export enum CopilotRole {
  USER = 0,
  SYSTEM = 1,
  ASSISTANT = 2,
  TOOL = 3,
}

export enum NotificationType {
  INVITATION = 1,
  USERTAGGED = 2,
  PostDraftWasCreated = 3,
  PostWasPublished = 4,
  PostWasScheduled = 5,
  ScheduledPostWasPublished = 6,
  ExamplePostCreated = 7,
  UserJoinedTheBrand = 8,
  SystemNotification = 9,
  ReminderPostReadyForPublishing = 10,
}

export enum OrganizationType {
  COMPANY = 1,
  PERSONAL = 2,
}

export enum BillingPeriod {
  MONTHLY = 1,
  ANNUALLY = 2,
}

export enum OrganizationUserRole {
  OWNER = 1,
  MEMBER = 2,
  VIEWER = 3,
}

export enum ContentLanguages {
  ENGLISH = 1,
  // GEORGIAN = 2,
  PORTUGUESE = 3,
  SPANISH = 4,
  POLISH = 5,
  CHINESE = 6,
  JAPANESE = 7,
  GERMAN = 8,
  FRENCH = 9,
  DUTCH = 10,
  TURKISH = 11,
  ITALIAN = 12,
  SWEDISH = 13,
}

export enum CopilotType {
  DEFAULT = 1,
  POST = 2,
  ORGANIZATIONCREATIONCOMPANY = 3,
  ORGANIZATIONCREATIONPERSONAL = 4,
}
export enum SocialNetworkType {
  FACEBOOK = 1,
  INSTAGRAM = 2,
  LINKEDIN = 3,
  TWITTER = 4,
  PINTEREST = 5,
  // TIKTOK = 6,
  REDDIT = 7,
  TELEGRAM = 8,
  SLACK = 9,
  DISCORD = 10,
}
export const SocialNetworkTypeName = {
  [SocialNetworkType.FACEBOOK]: "Facebook",
  [SocialNetworkType.INSTAGRAM]: "Instagram",
  [SocialNetworkType.TWITTER]: "X (Twitter)",
  [SocialNetworkType.LINKEDIN]: "Linkedin",
  [SocialNetworkType.PINTEREST]: "Pinterest",
};

export enum SocialNetworkCharacterLimit {
  FACEBOOK = 63206,
  INSTAGRAM = 2200,
  LINKEDIN = 3000,
  TWITTER = 280,
  PINTEREST = 500,
  REDDIT = 40000,
}

export enum ImageGenerationStyle {
  Photorealistic = 1,
  FacePortrait = 2,
  PopArt = 3,
  Cartoon = 4,
  SplatterPaint = 5,
  BlogIllustration = 6,
  MinimalLineArt = 7,
  Neon = 8,
  AnimatedCharacter = 9,
  Painting = 10,
  NONE = 0
}

export enum ImageGenerationAspectRatio {
  "21:9" = "21:9",
  "16:9" = "16:9",
  "4:3" = "4:3",
  "1:1" = "1:1",
  "3:4" = "3:4",
  "9:16" = "9:16",
  "9:21" = "9:21"
}

export enum TemplateTokenType {
  TEXT = 1,
  IMAGE = 2,
}
