import { useEffect, useState } from "react";
import { useGetFoldersAndFiles } from "../../../api/library/foldersAndFiles/get";
import useScrollStatus from "../../../hooks/useScrollStatus";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { useCreationStore } from "../../../store/creationStore";
import { useLibraryStore } from "../../../store/libraryStore";
import { FileType } from "../../../utils/enums/Enums";

export const useFolderHistory = ({
  wrapperRef,
  searchValue,
}: {
  wrapperRef?: any;
  searchValue?: string;
}) => {
  const { isFetching: isBrandFetching } = useGetCurrentBrand();
  const { isPostOpen } = useCreationStore();
  const {
    page,
    setPage,
    parentFolder,
    setParentFolder,
    folderHistory,
    setFolderHistory,
    foldersAndFiles,
    setFoldersAndFiles,
  } = useLibraryStore();
  useEffect(() => {
    if (folderHistory.length === 0) {
      setFolderHistory([
        {
          id: null,
          title: parentFolder ? parentFolder!.title : "Assets",
          type: "transparent",
          callback: () => {
            setFoldersAndFiles([]);
            setPage(1);
            setParentFolder(null);
          },
        },
      ]);
    }
  }, [folderHistory]);
  const [fileType, setFileType] = useState<FileType | undefined>(undefined);
  const [source, setSource] = useState({
    generatedOnly: false,
    uploadedOnly: false,
  });
  const { data, isFetching, isLoading } = useGetFoldersAndFiles({
    page: page,
    pageSize: 25,
    parentFolderId: parentFolder?.id,
    fileType: fileType,
    ...source,
    keyword: searchValue
      ? searchValue.length > 0
        ? searchValue
        : undefined
      : undefined,
  });

  useEffect(() => {
    setFoldersAndFiles([]);
    setPage(1);
  }, [searchValue, setPage, setFoldersAndFiles]);

  const { isScrolledToBottom, isFullyOnScreen } = useScrollStatus(wrapperRef);

  useEffect(() => {
    let debounceTimeout: NodeJS.Timeout;
    if (!isFetching && !isLoading && data && data.data.showNext) {
      if (isScrolledToBottom || isFullyOnScreen) {
        debounceTimeout = setTimeout(() => {
          setPage((prevPage: number) => prevPage + 1);
        }, 50);
      }
    }

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [isScrolledToBottom, isFullyOnScreen]);

  useEffect(() => {
    if (isBrandFetching) {
      setFoldersAndFiles([]);
      setPage(1);
      setParentFolder(null);
    }
    if (data && !isFetching && !isBrandFetching) {
      setFoldersAndFiles([...foldersAndFiles, ...data.data.foldersAndFiles]);
    }
  }, [data, isBrandFetching]);

  useEffect(() => {
    setFolderHistory((prev) => {
      if (parentFolder === null) {
        return prev.slice(0, 1);
      } else {
        return prev.slice(
          0,
          prev.findIndex((item) => item.id === parentFolder?.id) + 1
        );
      }
    });
  }, [parentFolder, page]);

  useEffect(() => {
    if (!isPostOpen && data && !isFetching && !isBrandFetching) {
      setFoldersAndFiles([...data.data.foldersAndFiles]);
    }
  }, [isPostOpen]);

  return {
    folderHistory,
    setFoldersAndFiles,
    setPage,
    foldersAndFiles,
    setFolderHistory,
    setParentFolder,
    isFetching,
    parentFolderId: parentFolder?.id,
    defaultFolderId: data?.data.defaultFolderId,
    fileType,
    setFileType,
    setSource,
    source,
  };
};
