import { ISingleFIleVM } from "../../../../../utils/interfaces/IAssets";
import {
  ISocialNetworkPostFileVM,
  IStockImageVM,
} from "../../../../../utils/interfaces/IPost";

export const convertLibraryFileIntoSocialNetworkPostFile = (
  file: ISingleFIleVM
) => {
  let obj: ISocialNetworkPostFileVM = {
    created: file.created,
    createdBy: file.createdBy,
    id: null,
    isGenerated: file.isGenerated,
    isSelected: true,
    isTemplateUsed: file.isTemplateUsed,
    lastModified: file.lastModified,
    thumbnailLargeUrl: file?.thumbnailLargeUrl || file.url,
    thumbnailMediumUrl: file?.thumbnailMediumUrl || file.url,
    thumbnailSmallUrl: file?.thumbnailSmallUrl || file.url,
    url: file.url,
    templateId: file.templateId,
    tokens: file.tokens,
    templateOutputUrl: file.templateOutputUrl,
    template: file.template,
  };
  return obj;
};

export const stockInterfaceToPostFile = (image: IStockImageVM) => {
  let obj: ISocialNetworkPostFileVM = {
    isGenerated: false,
    isSelected: true,
    isTemplateUsed: false,
    lastModified: null,
    thumbnailLargeUrl: image?.thumbnailLargeUrl || image.url,
    thumbnailMediumUrl: image?.thumbnailMediumUrl || image.url,
    thumbnailSmallUrl: image?.thumbnailSmallUrl || image.url,
    url: image.url,
  };
  return obj;
};
