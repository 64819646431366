import React, { useState } from "react";
import { ISingleFrameVM } from "../../../../utils/interfaces/IFrames";
import { useFramesStore } from "../../../../store/framesStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPalette,
  faSpinner,
  faStar,
} from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import { useTemplateRendererHelperFunctions } from "../../hooks/useTemplateRendererHelperFunctions";
import SButton from "../../../../design-system/SButton";
import { Tooltip } from "react-tooltip";
import { usePostFavouriteFrame } from "../../../../api/frames/favourites/post";
import { useDeleteFavouriteFrame } from "../../../../api/frames/favourites/delete";

function FramesGroup({
  dKey,
  groupName,
  templates,
  favourites,
}: {
  dKey?: string;
  groupName: string;
  favourites?: ISingleFrameVM[];
  templates: ISingleFrameVM[];
}) {
  const { mutateAsync: postFavouriteFrame, isPending: isFavoriting } =
    usePostFavouriteFrame();
  const { mutateAsync: deleteFavouriteFrame, isPending: isDeleting } =
    useDeleteFavouriteFrame();
  const { activeFrame, selectedImage } = useFramesStore();
  const isActive = (templateId: number) => {
    if (activeFrame) {
      if (templateId === activeFrame?.id) {
        return true;
      }
    }
    if (selectedImage?.template) {
      if (activeFrame && activeFrame?.id !== selectedImage?.template?.id) {
        return false;
      }
      if (templateId === selectedImage?.template?.id) {
        return true;
      }
    }
    return false;
  };
  const { renderTemplate, saveTemplate } = useTemplateRendererHelperFunctions();
  const { whichFrameIsRendering } = useFramesStore();
  const [loadingId, setLoadingId] = useState<number | null>(null);

  return (
    <div className="mt-3" key={dKey}>
      <p className="text-md-m text-graycool-800">
        {groupName === "Halloween" && "🎃 "}
        {groupName}
        {groupName === "Halloween" && (
          <span className="text-gray-500 text-sm"> (Holiday Special)</span>
        )}
      </p>
      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[8px] mt-3">
        {templates.map((template) => (
          <>
            <div
              className={`${isActive(template.id) && "border-[2px] border-brand-600 rounded-[18px] p-[4px] border-solid"} rounded-[18px] relative`}
            >
              {!favourites?.find((item) => item.id === template.id) && (
                <div className="absolute w-full top-[12px] px-2 flex justify-between items-center">
                  {template.isBranded && (
                    <div className="z-20">
                      <Tooltip
                        id={`is-branded-${template.id}`}
                        place="top-start"
                      >
                        This template uses your brand colors
                      </Tooltip>
                      <div
                        className="bg-gradient-to-r text-base-white from-brand-500 to-pink-500 px-2 py-1 rounded-md flex items-center gap-2"
                        data-tooltip-id={`is-branded-${template.id}`}
                      >
                        <FontAwesomeIcon icon={faPalette} className="text-sm" />
                        <p className="text-xs">Branded</p>
                      </div>
                    </div>
                  )}
                  <div className="z-20 ml-auto">
                    <Tooltip
                      id={`add-to-favorites-${template.id}`}
                      place="bottom-end"
                    >
                      Add to favorites
                    </Tooltip>
                    <SButton
                      onClick={() => {
                        setLoadingId(template.id);
                        postFavouriteFrame({
                          templateIds: [template.id],
                        });
                      }}
                      data-tooltip-id={`add-to-favorites-${template.id}`}
                      icon={faStar}
                      isLoading={loadingId === template.id && isFavoriting}
                      size="sm"
                      type="tertiaryColor"
                      iconProps={{
                        className: `text-yellow-400  ${loadingId === template.id && isFavoriting && "animate-spin"}`,
                      }}
                    />
                  </div>
                </div>
              )}
              {favourites?.find((item) => item.id === template.id) && (
                <div className="absolute w-full top-[12px] px-2 flex justify-between items-center">
                  {template.isBranded && (
                    <div className="z-20">
                      <Tooltip
                        id={`is-branded-${template.id}`}
                        place="top-start"
                        className=""
                      >
                        This template uses your brand colors
                      </Tooltip>
                      <div
                        className="bg-gradient-to-r text-base-white from-brand-500 to-pink-500 px-2 py-1 rounded-md flex items-center gap-2"
                        data-tooltip-id={`is-branded-${template.id}`}
                      >
                        <FontAwesomeIcon icon={faPalette} className="text-sm" />
                        <p className="text-xs">Branded</p>
                      </div>
                    </div>
                  )}
                  <div className="z-20 ml-auto">
                    <Tooltip id={`add-to-favorites-${template.id}`} place="top">
                      Remove from favorites
                    </Tooltip>
                    <SButton
                      onClick={() => {
                        setLoadingId(template.id);
                        deleteFavouriteFrame({
                          templateIds: [template.id],
                        });
                      }}
                      data-tooltip-id={`add-to-favorites-${template.id}`}
                      icon={faStarSolid}
                      isLoading={loadingId === template.id && isDeleting}
                      size="sm"
                      type="tertiaryColor"
                      iconProps={{
                        className: `text-yellow-400 ${loadingId === template.id && isDeleting && "animate-spin"}`,
                      }}
                    />
                  </div>
                </div>
              )}
              {whichFrameIsRendering === template.id && (
                <div
                  className={`absolute top-0 left-0 w-full h-full flex justify-center items-center bg-[rgba(0,0,0,0.6)]  ${
                    isActive(template.id) ? "rounded-[16px]" : "rounded-[8px]"
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    className="text-base-white "
                  />
                </div>
              )}
              <img
                onClick={() => {
                  if (
                    whichFrameIsRendering === undefined &&
                    !isActive(template.id)
                  )
                    renderTemplate({
                      template,
                    }).then((res) => {
                      saveTemplate(res?.data);
                    });
                }}
                key={template.id}
                className={`
              ${isActive(template.id) ? "rounded-[10px]" : "rounded-[8px]"}
              ${whichFrameIsRendering !== undefined ? "cursor-default" : isActive(template.id) ? "cursor-default" : "cursor-pointer"}
               w-full hover:opacity-70 transition-all aspect-square object-cover ${template.thumbnail === null ? "bg-gray-100" : ""}`}
                src={template.thumbnail}
                alt={template.name}
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default FramesGroup;
