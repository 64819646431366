import { useState } from "react";
import { useGetWindowWidth } from "../../../hooks/useGetWindowWidth";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";
import { BrandPostIdea } from "../../../utils/interfaces/IBrand";

export const useContentIdeas = ({
  postIdeas,
}: {
  postIdeas?: BrandPostIdea[];
}) => {
  const { handleOpenPost } = useCreationHelperFunctions();
  const [viewAll, setViewAll] = useState(false);
  const { windowWidth } = useGetWindowWidth();

  const getVisiblePostIdeaCount = () => {
    if (windowWidth > 1200) return 5;
    if (viewAll) return postIdeas?.length;
    if (windowWidth <= 880) return 2;
    if (windowWidth <= 1000) return 4;
    return 5;
  };

  return {
    setViewAll,
    getVisiblePostIdeaCount,
    handleOpenPost,
    viewAll,
    windowWidth,
  };
};
