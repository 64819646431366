import { faSend } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import SInput from "../../design-system/SInput";
import SButton from "../../design-system/SButton";
import Message from "./comments/Message";
import { useCreationStore } from "../../store/creationStore";
import { useUpdatePost } from "./hooks/useUpdatePost";
// @ts-ignore
import { MentionsInput, Mention } from "react-mentions";
import { useDisplayMembers } from "../brand/hooks/useDisplayMembers";
import { useAccountStore } from "../../store/accountStore";

function CommentsModal() {
  const inputRef = useRef<any>(null);
  const [value, setValue] = useState("");
  const { postData } = useCreationStore();
  const { updateComments } = useUpdatePost();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { activeMembers } = useDisplayMembers();
  const { personalInfo } = useAccountStore();
  useEffect(() => {
    document.getElementById("post__comments__chat")?.scrollTo({
      top: 1000000,
      left: 0,
      behavior: "auto",
    });
  }, [postData]);

  const [users, setUsers] = useState<
    {
      id: string;
      display: string;
    }[]
  >([]);

  useEffect(() => {
    if (activeMembers) {
      const members = activeMembers.data
        .filter((item) => item.email !== personalInfo?.email)
        .map((item) => ({
          id: item.userId,
          display: item.name,
        }));
      setUsers(members);
    }
  }, [activeMembers]);

  const handleSubmit = () => {
    const trimmedValue = value.trim().replace(/\n/g, "");

    if (trimmedValue.length > 0) {
      const mentionPattern =
        /@\{\s*"name"\s*:\s*"(.*?)"\s*,\s*"id"\s*:\s*"(.*?)"\s*\}/g;
      const mentions = [];
      let match;
      while ((match = mentionPattern.exec(trimmedValue)) !== null) {
        mentions.push({
          name: match[1],
          id: match[2],
        });
      }
      const filteredMentions = mentions.reduce<
        Array<{ name: string; id: string }>
      >((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      updateComments({
        text: trimmedValue,
        taggedUserIds: filteredMentions.map((item) => item.id),
      }).finally(() => {
        setValue("");
        document.getElementById("post__comments__chat")?.scrollTo({
          top: 1000000,
          left: 0,
          behavior: "smooth",
        });
      });
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={`z-50 bg-transparent rounded-b-[16px] shadow-xl border-b border-x border-gray-200`}
    >
      {postData?.comments && postData.comments.length > 0 && (
        <div
          id="post__comments__chat"
          className="overflow-auto flex flex-col gap-[12px] max-h-[240px] px-[16px] py-[16px] bg-base-white w-full"
        >
          {postData?.comments.map((item) => <Message comment={item} />)}
        </div>
      )}
      <div className="px-[12px] py-[12px] border-t border-gray-200 flex flex-col gap-[12px] bg-base-white rounded-b-[16px]">
        {postData?.comments && postData.comments.length < 1 && (
          <p className="p-1 text-xs text-gray-400">Member Comments</p>
        )}
        <div className={"flex items-center gap-[12px]"}>
          {/* <SInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Comment..."
            className="w-full"
            inputClassName="text-sm"
            onKeyDown={(e) => {
              if (e.code === "Enter" && value.length > 0) {
                updateComments({ text: value }).finally(() => {
                  setValue("");
                  document.getElementById("post__comments__chat")?.scrollTo({
                    top: 1000000,
                    left: 0,
                    behavior: "smooth",
                  });
                });
              }
            }}
          /> */}
          <MentionsInput
            ref={inputRef}
            style={{
              width: "100%",
              height: "42px",
            }}
            onKeyDown={(e: any) => {
              if (e.code === "Enter" && value.length > 0) {
                e.preventDefault();
                handleSubmit();
              }
            }}
            placeholder="Add Comment. Use '@' for mention"
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            a11ySuggestionsListLabel={"Suggested mentions"}
            classNames={["mentionsInputDiv"]}
            customSuggestionsContainer={(children: React.ReactNode) => (
              <div className="rounded-[12px] shadow-lg bg-base-white absolute overflow-hidden bottom-[calc(100%+24px)] min-w-[200px] w-full z-[100]">
                {children}
              </div>
            )}
          >
            <Mention
              renderSuggestion={(suggestion: any) => (
                <div className="px-[12px] py-[12px] z-[100]  truncate ... text-sm-m hover:bg-gray-100 text-gray-700">
                  {suggestion.display}
                </div>
              )}
              markup={`@{
                "name": "__display__",
                "id": "__id__"
              }`}
              className="mention-item"
              data={users}
            />
          </MentionsInput>
          <SButton
            // onClick={() => {
            //   if (value.length > 0) {
            //     updateComments({ text: value }).finally(() => {
            //       setValue("");
            //       document.getElementById("post__comments__chat")?.scrollTo({
            //         top: 1000000,
            //         left: 0,
            //         behavior: "smooth",
            //       });
            //     });
            //   }
            // }}
            onClick={() => {
              if (value.length > 0) {
                handleSubmit();
              }
            }}
            icon={faSend}
            size="sm"
            disabled={value.length < 1}
            className="absolute right-[20px] bottom-[16px]"
            type={value.length > 0 ? "tertiaryColor" : "tertiaryGray"}
          />
        </div>
      </div>
    </div>
  );
}

export default CommentsModal;
