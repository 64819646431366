import SModalBase from "../../design-system/SModalBase";
import {
  faDownload,
  faPlus,
  faTrash,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ExpandedRename } from "../creation/Tabs/Media/Library/ExpandedRename";
import { useAccountStore } from "../../store/accountStore";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { IExpandedImage } from "../Library/LibraryView";
import { useGridCardActions } from "../Library/hooks/useGridCardActions";
import { ConfirmDelete } from "../Library/ConfirmDelete";
import { toast } from "../../hooks/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-solid-svg-icons";
import { isVideo } from "../Library/hooks/getFileType";
import { ISingleFIleVM } from "../../utils/interfaces/IAssets";
import { useCreationLibraryHelperFunctions } from "../creation/Tabs/Media/hooks/useCreationLibraryHelperFunctions";

export interface IExpandedImageModalProps {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<IExpandedImage | null>>;
  expandedImage?: IExpandedImage;
  source?: string;
  shouldDelete?: boolean;
  fileObj?: ISingleFIleVM;
}

function ExpandedImageModal({
  isOpen,
  onClose,
  expandedImage,
  source,
  shouldDelete = false,
  fileObj,
}: IExpandedImageModalProps) {
  const { personalInfo } = useAccountStore();
  const { insertMediaForAllSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  const {
    handleDownload,
    updateFile,
    handleDelete,
    openConfirmation,
    setOpenConfirmation,
    deleteFile,
  } = useGridCardActions();
  const [editExpandedAsset, setEditExpandedAsset] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const isExpandedVideo = isVideo(source || "");

  useEffect(() => {
    if (isExpandedVideo) setIsLoaded(true);
  }, [isExpandedVideo]);

  return (
    <>
      {openConfirmation && (
        <ConfirmDelete
          title="Are you sure you want to delete this asset?"
          setOpenModal={() => setOpenConfirmation(false)}
          openModal={openConfirmation}
          onCancel={() => setOpenConfirmation(false)}
          onConfirm={() => {
            deleteFile({ id: expandedImage?.id || 0 })
              .then(() => {
                toast(`Asset deleted successfully`, "success");
              })
              .catch((err) => {
                toast(err.response.data.message, "error");
              });
            onClose(null);
            setEditExpandedAsset(false);
          }}
        />
      )}
      <SModalBase
        showX={false}
        className="relative"
        dialogPanelClassName="max-md:flex mb-10 max-sm-m:max-w-[calc(100%-60px)] max-sm-m:mr-[35px] max-w-[80dvw] max-h-[80dvh] max-sm-m:max-h-[60dvh]"
        id="expanded-image-modal"
        isOpen={isOpen}
        onClose={() => {
          onClose(null);
          setEditExpandedAsset(false);
        }}
      >
        {expandedImage &&
          personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
            <ExpandedRename
              onEdit={setEditExpandedAsset}
              onSave={updateFile}
              expandedImage={expandedImage}
              isEditing={editExpandedAsset}
            />
          )}
        <SButton
          onClick={() => {
            onClose(null);
            setEditExpandedAsset(false);
          }}
          icon={faX}
          size="sm"
          type="secondaryGray"
          className="absolute left-[calc(100%+6px)] top-0"
        />
        {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
          <SButton
            onClick={(e: any) =>
              handleDownload({ e, url: source || "", name: "" })
            }
            icon={faDownload}
            size="sm"
            type="secondaryGray"
            className="absolute left-[calc(100%+6px)] top-11"
          />
        )}
        {fileObj && (
          <SButton
            onClick={() => {
              insertMediaForAllSocialNetworkType(fileObj);
              onClose(null);
              setEditExpandedAsset(false);
            }}
            icon={faPlus}
            size="sm"
            type="secondaryGray"
            className="absolute left-[calc(100%+6px)] top-[88px]"
          />
        )}
        {personalInfo?.userRole !== OrganizationUserRole.VIEWER &&
          shouldDelete && (
            <SButton
              onClick={(e: any) =>
                handleDelete({ e, id: expandedImage?.id, cardType: "File" })
              }
              icon={faTrash}
              size="sm"
              iconProps={{
                className: "text-error-500",
              }}
              type="secondaryGray"
              className="absolute left-[calc(100%+6px)] top-[88px]"
            />
          )}
        {!isExpandedVideo &&
          (isLoaded ? (
            <img
              onLoad={() => setIsLoaded(true)}
              src={source}
              alt=""
              className={
                "max-sm-m:max-h-[50dvh] max-h-[70dvh] rounded-xl shadow-md object-cover"
              }
            />
          ) : (
            <div className="w-[600px] max-md:w-[300px] max-md:h-[300px] h-[50dvh] animate-pulse bg-gray-300 rounded-lg flex justify-center items-center">
              <FontAwesomeIcon
                icon={faImage}
                className="text-[140px] text-gray-400"
              />
            </div>
          ))}
        {!isLoaded && (
          <img
            onLoad={() => setIsLoaded(true)}
            src={source}
            alt="image"
            style={{ display: "none" }}
          />
        )}
        {isExpandedVideo && (
          <video
            onLoad={() => setIsLoaded(true)}
            className="max-sm-m:max-h-[50dvh] max-h-[70dvh] rounded-xl shadow-md"
            autoPlay
            controls
          >
            <source src={source} type="video/mp4" />
          </video>
        )}
      </SModalBase>
    </>
  );
}

export default ExpandedImageModal;
