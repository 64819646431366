import React from "react";
import {
  addDays,
  endOfMonth,
  format,
  isSameDay,
  isSameMonth,
  isToday,
  startOfMonth,
} from "date-fns";
import { useCalendarStore } from "../../store/calendarStore";
import MonthCard from "./month/MonthCard";
import CalendarHead from "./month/CalendarHead";
import DayOfMonth from "./month/DayOfMonth";
import { getMonthDays } from "./functions/daysMapping";
import { useGetCalendarData } from "../../api/calendar/get";
import { useCalendarHelper } from "./hooks/useCalendarHelper";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";
import { useAccountStore } from "../../store/accountStore";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useGetWorkspaceUsage } from "../../api/workspaces/limits/get";
import { toast } from "../../hooks/toast";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function MonthView({
  setIsWhichSocialNetworksSelectorOpen,
}: {
  setIsWhichSocialNetworksSelectorOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) {
  const { handleOpenPost } = useCreationHelperFunctions();
  const { currentDate } = useCalendarStore();
  const days: Date[] = getMonthDays(currentDate);
  const { convertToISOAndChangeTimezone } = useCalendarHelper();
  const { data: posts } = useGetCalendarData({
    from: convertToISOAndChangeTimezone(
      new Date(format(startOfMonth(currentDate), "yyyy-MM-dd'T'00:00:00"))
    ),
    to: convertToISOAndChangeTimezone(
      new Date(
        format(addDays(endOfMonth(currentDate), 1), "yyyy-MM-dd'T'00:00:00")
      )
    ),
  });
  const { personalInfo } = useAccountStore();
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  return (
    <div className="shadow-xs border-[1px] rounded-[8px] overflow-hidden">
      <CalendarHead />
      <div className="flex bg-gray-200 text-xs min-h-[759px]">
        <div className="w-full grid grid-cols-7 grid-rows-5 divide-x divide-y divide-gray-100">
          {days.map((day) => (
            <div
              onClick={() => {
                if (!workspaceUsage?.data.canUserAddPosts) {
                  toast(
                    "You have reached the limit of posts for your workspace.",
                    "error"
                  );
                  return;
                }
                personalInfo?.userRole !== OrganizationUserRole.VIEWER &&
                  handleOpenPost({
                    onSocialNetworkSelectorOpen: () => {
                      setIsWhichSocialNetworksSelectorOpen(true);
                    },
                  });
              }}
              key={day.toISOString()}
              className={classNames(
                isSameMonth(day, currentDate)
                  ? isToday(day)
                    ? "bg-brand-25"
                    : "bg-white"
                  : "bg-gray-50 text-gray-500",
                `relative flex-1 p-[16px] ${personalInfo?.userRole === OrganizationUserRole.VIEWER ? "cursor-default" : "cursor-pointer"}`
              )}
            >
              <DayOfMonth day={day} />
              <div className="mt-[8px] max-h-[80px] overflow-auto">
                {posts &&
                  posts.data
                    .filter(
                      (fPost) =>
                        isSameDay(new Date(fPost.releaseDate as string), day) &&
                        !fPost.isExamplePost
                    )
                    .map((post, i) => (
                      <MonthCard
                        className={`${i !== 0 && "mt-[6px]"}`}
                        post={post}
                      />
                    ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
