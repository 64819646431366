export const ContentIdeaSkeleton = () => {
  return (
    <div className="flex w-full h-full flex-col p-2 gap-2 justify-center max-sm:w-full bg-gray-100 rounded-lg animate-pulse">
      <div className="w-full flex items-center justify-between">
        <div className="h-4 w-10 bg-gray-200 rounded" />
        <div className="h-4 w-4 bg-gray-200 rounded" />
      </div>
      <div className="h-4 w-full bg-gray-200 rounded" />
      <div className="h-4 w-3/4 bg-gray-200 rounded" />
    </div>
  );
};
