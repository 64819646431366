import SBadge from "../../design-system/SBadge";
import { AnimatePresence, motion } from "framer-motion";
import {
  faArrowRightArrowLeft,
  faCheck,
  faClose,
  faCopy,
  faRefresh,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRenderSocialScore } from "./hooks/useRenderSocialScore";
import { Tooltip } from "react-tooltip";

export const SocialScore = () => {
  const {
    socialScore,
    isSocialScoreLoading,
    openMessage,
    setOpenMessage,
    color,
    setCopy,
    copy,
    icon,
    setImproveIdea,
    isMessaging,
    activeTab,
    setActiveTab,
    socialScoreRef,
    isPostPublishedOrScheduled,
  } = useRenderSocialScore();
  return (
    <>
      {socialScore && (
        <div id="social-score-item">
          <SBadge
            onClick={() => setOpenMessage((value) => !value)}
            icon={icon}
            color={isSocialScoreLoading ? "gray" : color}
            size="sm"
            className={`rounded-lg relative cursor-pointer select-none`}
            id="social-scoring"
          >
            <p>{!isSocialScoreLoading && socialScore?.score} / 10</p>
            {isSocialScoreLoading && (
              <FontAwesomeIcon className="animate-spin" icon={faRefresh} />
            )}
            <AnimatePresence>
              {openMessage && !isSocialScoreLoading && (
                <motion.div
                  ref={socialScoreRef}
                  onClick={(e) => e.stopPropagation()}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  transition={{ duration: 0.1 }}
                  exit={{ opacity: 0, height: 0 }}
                  className="absolute max-md:right-[-25px] right-0 z-10 cursor-default top-[calc(100%+10px)] select-text bg-base-white p-5 rounded-lg shadow-xl border border-gray-200 w-[400px] max-md:w-[340px]"
                >
                  <FontAwesomeIcon
                    onClick={() => setOpenMessage(false)}
                    size="xl"
                    className="absolute text-gray-800 right-3 top-2 cursor-pointer"
                    icon={faClose}
                  />
                  <motion.p
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    transition={{ duration: 0.1 }}
                    exit={{ opacity: 0, height: 0 }}
                    className="text-sm text-gray-800"
                  >
                    {socialScore?.message}
                  </motion.p>
                  <div className="w-full mt-2 flex items-center justify-start gap-3">
                    <Tooltip id="ask-jason-improvement">
                      {isPostPublishedOrScheduled()
                        ? "Cannot improve already scheduled or published post"
                        : "Ask Jason"}
                    </Tooltip>
                    <div data-tooltip-id="ask-jason-improvement">
                      <FontAwesomeIcon
                        onClick={() => {
                          if (isMessaging || isPostPublishedOrScheduled()) {
                            return;
                          }
                          if (activeTab !== "brief") {
                            setActiveTab("brief");
                          }
                          setImproveIdea(socialScore?.message);
                          setOpenMessage(false);
                        }}
                        icon={faArrowRightArrowLeft}
                        size="lg"
                        className={`transition-all ${isMessaging || isPostPublishedOrScheduled() ? "text-gray-400 cursor-default" : "text-gray-900 hover:text-gray-600 cursor-pointer "}`}
                      />
                    </div>
                    <Tooltip id="copy-improvement">
                      {copy ? "Copied!" : "Copy"}
                    </Tooltip>
                    <div
                      data-tooltip-id="copy-improvement"
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          socialScore?.message || ""
                        );
                        setCopy(true);
                      }}
                    >
                      {!copy && (
                        <FontAwesomeIcon
                          className="text-gray-900 hover:text-gray-600 transition-all "
                          size="lg"
                          icon={faCopy}
                        />
                      )}
                      {copy && (
                        <FontAwesomeIcon
                          size="lg"
                          className="text-success-500 "
                          icon={faCheck}
                        />
                      )}
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </SBadge>
        </div>
      )}
    </>
  );
};

export default SocialScore;
