import { useEffect } from "react";
import { useBrandStore } from "../../../store/brandStore";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { getQuery } from "../../../hooks/functions/queries";
import { closeAllToast, toast } from "../../../hooks/toast";

export const useBrandEffects = ({
  setIsRegenerateModalOpen,
  isRegenerateModalOpen,
  setSelected,
}: {
  isRegenerateModalOpen: boolean;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  setIsRegenerateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { refetch: refetchCurrentBrandInfo } = useGetCurrentBrand();
  const { currentBrand } = useBrandStore();
  const querySection = getQuery("section");
  // tu queryshi gamokva konkretuli sekciis saxeli gadavrto im sekciaze
  useEffect(() => {
    if (querySection) {
      switch (querySection) {
        case "connections":
          setSelected(2);
          break;
      }
    } else {
      setSelected(0);
    }
  }, []);

  // tu brandis data araa generirebuli refetchi gavaketo da ise daisetos 3 wamiani intervalit
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (currentBrand && !currentBrand?.isBrandDataGenerated) {
      intervalId = setInterval(() => {
        refetchCurrentBrandInfo().then((res) => {
          if (res!.data!.data.isBrandDataGenerated) {
            if (intervalId) {
              clearInterval(intervalId as NodeJS.Timeout);
            }
          }
        });
      }, 3000);
    }
    if (
      currentBrand &&
      currentBrand.hasErrors &&
      currentBrand.isBrandDataGenerated
    ) {
      clearInterval(intervalId as NodeJS.Timeout);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId as NodeJS.Timeout);
      }
    };
  }, [currentBrand]);

  // tu brends erorebi akvs amovagdo toasti da gavxsna regeneratis modali
  useEffect(() => {
    if (currentBrand?.hasErrors) {
      setIsRegenerateModalOpen(true);
      if (isRegenerateModalOpen)
        toast(
          currentBrand?.errorMessage ||
            "Brand data has errors, please regenerate"
        );
    }
  }, [currentBrand]);
};
