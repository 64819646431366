import { ISocialNetworkPostScore } from "../../../utils/interfaces/IPost";

export const socialScoreColor = (socialScore?: ISocialNetworkPostScore) => {
  const colors: any = ["red", "warning", "success"];
  let color = colors[0];
  if (socialScore?.score && socialScore.score > 3 && socialScore.score <= 6)
    color = colors[1];
  if (socialScore?.score && socialScore.score > 6) color = colors[2];
  return color;
};
