import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
import { useFramesStore } from "../../../store/framesStore";
import { ISocialNetworkPostFileVM } from "../../../utils/interfaces/IPost";
import { updateImportClause } from "typescript";
import ContainImage from "../../utils/ContainImage";
import SButton from "../../../design-system/SButton";
import { useTemplateRendererHelperFunctions } from "../hooks/useTemplateRendererHelperFunctions";
import { isEqual } from "lodash";
import { isVideo } from "../../Library/hooks/getFileType";

function FramesPreview() {
  const { selectedImage, activeRenderedFrame, whichFrameIsRendering } =
    useFramesStore();
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const [images, setImages] = useState<ISocialNetworkPostFileVM[]>([]);
  const { saveTemplate } = useTemplateRendererHelperFunctions();
  useEffect(() => {
    if (getActiveSocialNetworkPost()?.files) {
      const updatedImages =
        getActiveSocialNetworkPost()?.files.filter(
          (file) => file.url !== selectedImage?.url
        ) || [];
      setImages(updatedImages);
    }
  }, [getActiveSocialNetworkPost()?.files, selectedImage]);

  return (
    <div className="relative w-full aspect-square [&_*_#save-template-button]:hover:opacity-100">
      {getActiveSocialNetworkPost()?.files && (
        <>
          {images.length >= 2 && (
            <div className="w-full h-[90%] absolute top-0 left-0 transform translate-x-8 translate-y-8 opacity-50">
              <ContainImage
                src={
                  images[1]?.templateOutputUrl || images[1].thumbnailLargeUrl
                }
                alt="Layer 1"
                className="w-full h-full shadow-lg"
              />
            </div>
          )}
          {images.length >= 1 && (
            <div className="w-full h-[90%] absolute top-0 left-0 transform translate-x-4 translate-y-4 opacity-75">
              <ContainImage
                src={
                  images[0]?.templateOutputUrl || images[0].thumbnailLargeUrl
                }
                alt="Layer 2"
                className="w-full h-full shadow-lg"
              />
            </div>
          )}
          {(selectedImage || activeRenderedFrame) && (
            <div className="relative w-full h-[90%]">
              {/* {activeRenderedFrame?.templateId !==
                selectedImage?.templateId && ( */}
              {/* )} */}
              {!isVideo(selectedImage?.url || "") && (
                <ContainImage
                  src={
                    activeRenderedFrame?.url ||
                    selectedImage?.templateOutputUrl ||
                    selectedImage?.thumbnailLargeUrl ||
                    ""
                  }
                  key={
                    activeRenderedFrame?.url ||
                    selectedImage?.templateOutputUrl ||
                    selectedImage?.thumbnailLargeUrl
                  }
                  alt="Layer 3"
                  className="w-full h-full shadow-lg"
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FramesPreview;
