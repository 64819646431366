import { useState } from "react";
import TableTrItemChannel from "./TableTrItemChannel";
import { faAdd, faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../design-system/SButton";
import { PostStatus, SocialNetworkType } from "../../../../utils/enums/Enums";
import { ISocialNetworkVM } from "../../../../utils/interfaces/ISocialNetworks";
import { useChannelsHelper } from "./functions/useChannelsHelper";
import { useCreationStore } from "../../../../store/creationStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Tooltip } from "react-tooltip";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import RemoveScheduleAgreement from "../../modals/RemoveScheduleAgreement";
import { useGetWindowWidth } from "../../../../hooks/useGetWindowWidth";

interface ITableTrItem {
  hasBackground: boolean;
  index: number;
  image: string;
  title: string;
  socialNetworkType: SocialNetworkType;
  website: string;
  pages: ISocialNetworkVM[];
}

function TableTrItem({
  hasBackground,
  image,
  title,
  index,
  socialNetworkType,
  pages,
}: ITableTrItem) {
  const {
    selectedSocialNetworks,
    postData,
    whichSocialNetworkTypeConnectIsLoading,
  } = useCreationStore();
  const { handleSelect, handleConnect } = useChannelsHelper();
  const [
    isRemoveScheduleAgreementModalOpen,
    setIsRemoveScheduleAgreementModalOpen,
  ] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const socMedias = [
    {
      icon: faXTwitter,
      color: "#000000",
      type: SocialNetworkType.TWITTER,
    },
    {
      icon: faLinkedin,
      color: "#0077B5",
      type: SocialNetworkType.LINKEDIN,
    },
    {
      icon: faInstagram,
      color: "#E1306C",
      type: SocialNetworkType.INSTAGRAM,
    },
    {
      icon: faPinterest,
      color: "#E60023",
      type: SocialNetworkType.PINTEREST,
    },
    {
      icon: faFacebook,
      color: "#0866FF",
      type: SocialNetworkType.FACEBOOK,
    },
  ];
  const viewURL = (item: ISocialNetworkVM) => {
    return postData?.socialNetworkPosts.find(
      (item2) => item2.socialNetworkType === item.socialNetworkType
    )?.postUrl;
  };
  const { windowWidth } = useGetWindowWidth();
  return (
    <tr
      className={`${hasBackground ? "bg-gray-50" : "bg-base-white"} text-left relative ${index !== 4 && "border-b-[1px]"} border-b-gray-200`}
    >
      <RemoveScheduleAgreement
        isOpen={isRemoveScheduleAgreementModalOpen}
        id={id}
        setIsOpen={setIsRemoveScheduleAgreementModalOpen}
      />
      <td className="px-[24px] py-[18px] flex  max-sm:flex-col gap-[12px] items-center">
        <FontAwesomeIcon
          icon={
            socMedias.filter((item) => item.type === socialNetworkType)[0].icon
          }
          color={
            socMedias.filter((item) => item.type === socialNetworkType)[0].color
          }
          className="w-[38px] h-[38px]"
        />
        <div className="h-full whitespace-nowrap flex flex-col justify-center">
          <p className="text-sm-m text-gray-900">{title}</p>
        </div>
      </td>
      <td className="px-[24px] py-[16px]">
        <div className="flex flex-col gap-3 items-start">
          <div className="flex flex-wrap gap-3 items-start">
            {postData &&
              pages.map((item) => {
                const sPostItem = postData.socialNetworkPosts.find(
                  (sPost) => sPost.socialNetworkType === item.socialNetworkType
                );
                return (
                  <div
                    key={item.id}
                    className="flex items-center gap-3 flex-nowrap"
                  >
                    <Tooltip id={`disabled-channel-${item.id}`}>
                      Post on this network is already{" "}
                      {sPostItem?.postStatus === PostStatus.PUBLISHED
                        ? "published"
                        : "scheduled"}
                    </Tooltip>
                    {(sPostItem?.postStatus === PostStatus.PUBLISHED ||
                      sPostItem?.postStatus === PostStatus.SCHEDULED) &&
                      item.id !== sPostItem.ayrshareSocialAccountId && (
                        <Tooltip id={`posted-but-disabled-channel-${item.id}`}>
                          You've already{" "}
                          {sPostItem?.postStatus === PostStatus.PUBLISHED
                            ? "published"
                            : "scheduled"}{" "}
                          on a different account
                        </Tooltip>
                      )}
                    <TableTrItemChannel
                      oneDisabled={
                        (sPostItem?.postStatus === PostStatus.PUBLISHED ||
                          sPostItem?.postStatus === PostStatus.SCHEDULED) &&
                        item.id !== sPostItem.ayrshareSocialAccountId
                      }
                      windowWidth={windowWidth}
                      dataTooltipId={
                        (sPostItem?.postStatus === PostStatus.PUBLISHED ||
                          sPostItem?.postStatus === PostStatus.SCHEDULED) &&
                        item.id === sPostItem.ayrshareSocialAccountId
                          ? `disabled-channel-${item.id}`
                          : `posted-but-disabled-channel-${item.id}`
                      }
                      page={item}
                      handleSelect={handleSelect}
                      isDisabled={
                        (sPostItem?.postStatus === PostStatus.PUBLISHED ||
                          sPostItem?.postStatus === PostStatus.SCHEDULED) &&
                        item.id === sPostItem.ayrshareSocialAccountId
                      }
                      status={sPostItem?.postStatus as PostStatus}
                      isChecked={selectedSocialNetworks.some(
                        (item2) => item2.id === item.id
                      )}
                      onRemoveSchedule={() => {
                        setIsRemoveScheduleAgreementModalOpen(true);
                        setId(sPostItem?.id ?? null);
                      }}
                      isScheduled={
                        sPostItem?.postStatus === PostStatus.SCHEDULED &&
                        item.id === sPostItem.ayrshareSocialAccountId
                      }
                      isPublished={
                        sPostItem?.postStatus === PostStatus.PUBLISHED &&
                        item.id === sPostItem.ayrshareSocialAccountId
                      }
                      viewURL={viewURL(item)}
                    />
                  </div>
                );
              })}
          </div>
          {
            <SButton
              isLoading={
                whichSocialNetworkTypeConnectIsLoading === socialNetworkType
              }
              onClick={() => {
                if (!whichSocialNetworkTypeConnectIsLoading)
                  handleConnect(socialNetworkType);
              }}
              className=""
              lIcon={faAdd}
            >
              Connect
            </SButton>
          }
        </div>
      </td>
    </tr>
  );
}

export default TableTrItem;
