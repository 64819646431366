import React from "react";
import { useCreationStore } from "../../store/creationStore";
import { AnimatePresence, motion } from "framer-motion";
import { duration } from "moment";

function SuggestedPrompts({
  setValue,
  sendMessage,
  disable,
}: {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: (t: string) => void;
  disable: boolean;
}) {
  const { suggestedPrompts, setSuggestedPrompts } = useCreationStore();
  const container = {
    hidden: { opacity: 0, translateY: 100 },
    show: {
      opacity: 1,
      translateY: 0,
      transition: { duration: 0.1, staggerChildren: 0.1 },
    },
  };
  const child = {
    hidden: { opacity: 0, translateY: 100 },
    show: { opacity: 1, translateY: 0, transition: { duration: 0.1 } },
  };
  return (
    <div className="min-h-[30px] mb-[10px] mt-[10px]">
      <AnimatePresence>
        {suggestedPrompts.length > 0 && !disable && (
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            exit="hidden"
            className={
              "flex gap-[6px] h-full overflow-x-auto overflow-y-hidden px-[16px]"
            }
          >
            {suggestedPrompts.map((prompt) => (
              <motion.p
                variants={child}
                onClick={() => {
                  if (prompt.autoSend) {
                    setSuggestedPrompts([]);
                    sendMessage(prompt.value);
                  } else {
                    setValue(prompt.value);
                  }
                  document
                    .getElementById("create-brand-chat-textarea")
                    ?.focus();
                }}
                className={
                  "cursor-pointer min-w-fit border h-[30px] border-gray-300 rounded-full text-sm-m text-gray-700 px-[12px] py-[4px] hover:bg-gray-200 hover:text-gray-800 transition-all"
                }
              >
                {prompt.label}
              </motion.p>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default SuggestedPrompts;
