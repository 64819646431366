import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getQuery } from "../../../hooks/functions/queries";

export const useSetDefaultWorkspaceSection = ({
  setSelected,
}: {
  setSelected: (value: number) => void;
}) => {
  const location = useLocation();
  useEffect(() => {
    const sectionQuery = getQuery("section") || getQuery("subsection");
    if (sectionQuery === "members") {
      setSelected(1);
    } else if (sectionQuery === "brands") {
      setSelected(2);
    } else if (sectionQuery === "billing") {
      setSelected(3);
    } else if (sectionQuery === "overview") {
      setSelected(0);
    }
  }, [location]);
};
