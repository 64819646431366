import BrandsListView from "../tables/BrandsListView";
import BrandsListSkeleton from "../Skeletons/BrandListSkeleton";
import { useDeleteSwitchBrand } from "./hooks/useDeleteSwitchBrand";

const WorkspaceBrands = () => {
  const { brands, isBrandsLoading, handleSwitch, workspaceUsers } =
    useDeleteSwitchBrand();

  return (
    <div className="flex flex-col gap-[32px] w-full pb-[60px]">
      {isBrandsLoading ? (
        <BrandsListSkeleton />
      ) : (
        <BrandsListView
          handleSwitch={handleSwitch}
          workspaceUsers={workspaceUsers}
          items={brands}
        />
      )}
    </div>
  );
};

export default WorkspaceBrands;
