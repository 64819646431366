import { useEffect, useState } from "react";
import { useContentStore } from "../../../store/contentStore";
import { useGetCurrentBrand } from "../../../api/brand/current/get";

export const useContentCount = () => {
  const { selected, setSelected } = useContentStore();
  const { data: currentBrand } = useGetCurrentBrand();

  const [selectedSort, setSelectedSort] = useState<"created" | "modified">(
    "created"
  );

  useEffect(() => {
    if (selected !== 0) {
      setSelected(0);
    }
    setSelectedSort("created");
  }, [currentBrand?.data.id]);

  return {
    selected,
    setSelected,
    currentBrand,
    selectedSort,
    setSelectedSort,
  };
};
