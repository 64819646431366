import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import { useContentIdeas } from "./hooks/useContentIdeas";
import { Dispatch, Fragment, SetStateAction } from "react";
//@ts-ignore
import memo from "../../assets/emojies/memo.svg";
import { BrandPostIdea } from "../../utils/interfaces/IBrand";

export const ContentIdea = ({
  setIsWhichSocialNetworksSelectorOpen,
  postIdeas,
  isPostIdeasFetching,
}: {
  setIsWhichSocialNetworksSelectorOpen: Dispatch<SetStateAction<boolean>>;
  postIdeas?: BrandPostIdea[];
  isPostIdeasFetching: boolean;
}) => {
  const {
    setViewAll,
    getVisiblePostIdeaCount,
    handleOpenPost,
    viewAll,
    windowWidth,
  } = useContentIdeas({ postIdeas });

  return (
    <div className="w-full min-h-[175px] border border-gray-200 bg-gray-50 rounded-xl p-3">
      <p className="text-md-sb mb-3 text-gray-700">Today's Post Ideas</p>
      {!isPostIdeasFetching && (
        <div className="w-full grid gap-3 grid-cols-5 max-lg:grid-cols-5 max-md:grid-cols-4 max-sm-m:grid-cols-2 max-sm:grid-cols-2">
          {postIdeas?.slice(0, getVisiblePostIdeaCount()).map((idea, i) => {
            return (
              <Fragment key={idea.id}>
                <SButton
                  onClick={() => {
                    handleOpenPost({
                      postIdea: `Create a post about the following idea: ${idea.idea}`,
                      onSocialNetworkSelectorOpen: () =>
                        setIsWhichSocialNetworksSelectorOpen(true),
                    });
                  }}
                  className={`h-auto flex flex-col !gap-1 !items-start justify-start`}
                  type="secondaryGray"
                  key={idea.id}
                >
                  <div className="w-full flex justify-between">
                    <p className="text-gray-500">#{i + 1} Idea</p>
                    <img
                      alt={"pen and paper"}
                      src={memo}
                      className="w-[14px]"
                    />
                  </div>
                  <p className="text-sm text-start mb-auto">{idea.idea}</p>
                </SButton>
              </Fragment>
            );
          })}
        </div>
      )}
      {windowWidth <= 1200 && (
        <div className="w-full flex justify-center items-center">
          <SButton
            lIcon={!viewAll ? faChevronDown : faChevronUp}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "auto" });
              setViewAll((val) => !val);
            }}
            type="tertiaryGray"
            className="mt-3 text-center flex items-center gap-[6px]"
          >
            <p>{!viewAll ? "View All" : "Minimize"}</p>
          </SButton>
        </div>
      )}
    </div>
  );
};
